import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <div className="grid grid-cols-1 md:grid-cols-body w-full">
      <div className="col-span-3 bg-black h-64 max-h-64 overflow-clip flex items-center justify-center">
        <StaticImage
          src="../images/be-nice-or-leave.png"
          alt="Sterling Tattoo Club Logo"
          objectFit="cover"
          objectPosition="50% 50%"
        />
      </div>
      <div className="col-start-2 col-span-1 flex flex-col justify-center items-center mt-12 px-4">
        {/* <h1
          className="text-3xl font-bold font-rubik words-tight text-black"
          style={{ wordSpacing: "-.5em" }}
        >
          Sterling Tattoo Club
        </h1> */}
        <h1 className="text-4xl font-rubik text-center">Walk Ins Welcome</h1>
        <p className="text-md text-center font-montserrat">
          Due to the handmade nature by which tattoos are created: Imperfections
          are to be expected and add to the aesthetic of which the tattoo was
          designed to be worn. Each tattoo’s originality, authenticity, and
          style is to be celebrated throughout the duration of adornment to your
          body.
        </p>
        <div>
          <p className="font-montserrat font-extrabold">
            <strong>Sterling Tattoo Club | Concord, NH</strong>
          </p>
        </div>

        {/* <div className="grid grid-cols-2 gap-8 mt-8 ">
          <h2
            className="text-3xl font-bold mb-4 font-rubik tracking-tighter words-tight text-black col-span-2 text-center"
            style={{
              wordSpacing: "-.5em",
            }}
          >
            MEET OUR ARTISTS
          </h2>
          <div>
            <StaticImage src="../images/cy/cy.png" alt="Cy" />
          </div>
          <div className="text-center flex flex-col justify-center items-center">
            <p className="text-2xl font-rubik tracking-tighter">CY</p>

            <button className="bg-[#a8a6a1] text-white p-3 px-6 text-sm">
              View Cy's Work
            </button>
          </div>

          <div className="text-center flex flex-col justify-center items-center">
            <p className="text-2xl font-rubik tracking-tighter">JOSH</p>

            <button className="bg-[#a8a6a1] text-white p-3 px-6 text-sm">
              View Josh's Work
            </button>
          </div>
          <div>
            <StaticImage src="../images/josh/josh.png" alt="Josh" />
          </div>
        </div> */}
      </div>
    </div>
  </Layout>
)
export const Head = () => <Seo title="Sterling Tattoo Club" />

export default IndexPage
